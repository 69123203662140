import React from "react"
import PropTypes from "prop-types"
import Logo from "../logo/logo-25"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import posed, { PoseGroup } from 'react-pose';
import Triangle from "../triangle/triangle"

const Overlay = posed.div({
  enter: {
    opacity: 1,
    transition: {
      default: { duration: 300 }
    }
  },
  exit: {
    opacity: 0,
    transition: {
      default: { duration: 300 }
    }
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      overlay: false,
      dropdownopen: false,
      navItems: this.props.navItems,
      socialItems: this.props.socialItems
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }
  
  toggleOverlay = () => {
    this.setState({
      overlay: !this.state.overlay
    });
  }
  
  toggleDropdown = () => {
    this.setState({
      dropdownopen: !this.state.dropdownopen
    });
  }

  toggleOff = () => {
    this.setState({
      overlay: false
    });
  }

  render() {
    return (
      <header className={ this.props.greyHeader ? `bg-grey-light relative py-8 pb-6 sm:py-10 lg:py-16 sm:pb-8 lg:pb-12` : `min-h-45 relative my-8 mb-6 sm:my-10 lg:my-16 sm:mb-8 lg:mb-12`}>
        <div className="container">
          <div className="flex flex-wrap items-center mb-4">
            <Link to="/" aria-label="Return to the homepage" className="grow max-w-[160px] mb-2">
              <Logo color={`secondary-dark`} modifier={`large`} />
            </Link>
            <div className="ml-auto">
              <nav aria-labelledby="mainmenulabel" className="m-nav">
                <span id="mainmenulabel" className="sr-only">Main Menu</span>
                <ul>
                {this.state.navItems.filter(item => !item.mobnavonly).map(({ title, url, children }, index) => (
                    <li key={index} className={ children ? `hidden lg:inline-block pb-5 has--dropdown` : `hidden lg:inline-block pb-5`} onMouseEnter={() => this.setState({ dropdownopen: true })} onMouseLeave={() => this.setState({ dropdownopen: false })} onClick={() => this.setState({ dropdownopen: false })}>
                      <Link to={`${url}`} activeClassName="is--active" partiallyActive={true} className="relative px-3 link hover:text-primary focus:text-primary xl:px-5 2xl:px-8">
                        {title}
                        { children && (
                          <span className="absolute bottom-0 left-0 right-0 flex flex-wrap justify-center -mb-6 transform rotate-90">
                            <span className="mx-auto">
                              <Triangle size={`small`} className="p-2" />
                            </span>
                          </span>
                        )}
                      </Link>


                      { children && (
                        
                          <ul className={this.state.dropdownopen ? `subnav absolute top-0 left-0 right-0 mt-12 pt-6 pb-0 z-30 ${this.props.greyHeader ? 'bg-grey mt-32' : 'bg-white' } hidden shadow-xl` : `shadow-xl subnav absolute top-0 left-0 right-0 mt-0 pt-6 pb-0 z-30 ${this.props.greyHeader ? 'bg-black' : 'bg-white' } hidden subnav--off`}>
                        
                          <div className="container px-8 pt-12">
                            <div className="w-10/12 mx-auto">
                            <div className="flex flex-wrap md:-mx-8">
                              {children.map(({ title, url, icon, children }, index) => 
                                <li key={index} className="w-full mb-12 md:w-1/2 lg:w-1/4 md:px-8" onClick={() => this.setState({ dropdownopen: false })}>
                                  { icon && (
                                    // @TODO convert this to SVG component?
                                    <img src={icon} alt={`${title} icon`} className="block w-8 h-8 mb-5" />
                                  )}

                                  <Link to={`${url}`} activeClassName="is--active" partiallyActive={true} className="block mb-6 text-2xl transition duration-500 ease-in-out h3 lg:text-2xl xl:text-3xl text-secondary hover:text-secondary-dark focus:text-secondary-dark">{ title }</Link>

                                  <nav aria-labelledby="childmenu">
                                    <span id="childmenu" className="sr-only">{ title } Menu</span>
                                    <ul className="block">
                                      <li className="block" key={index}>
                                        <Link to={url} className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> { title }</Link>
                                      </li>
                                      {children && (
                                        <>
                                          {children.map(({ title, url }, index) =>
                                            <li className="block" key={index}>
                                              <Link to={url} className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> { title }</Link>
                                            </li>
                                          )}

                                          
                                          {title === "Web Design" && (
                                            <li className="block" key={index}>
                                              <Link to="/services/web-design/ecommerce-agency/woocommerce-agency" className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> WooCommerce Agency</Link>
                                            </li>
                                          )}

                                          {title === "Web Design" && (
                                          <li className="block" key={index}>
                                            <Link to="/services/web-design/ecommerce-agency/shopify-agency" className="block mb-2 text-lg link hover:text-primary focus:text-primary"><span className={`mr-2 inline-block text-primary`}><Triangle size={`small`} /></span> Shopify Agency</Link>
                                          </li>
                                          )}
                                        </>
                                      )}
                                    </ul>
                                  </nav>
                                </li>
                              )}
                              <div className="hidden w-full mb-8 border lg:block lg:flex-1 md:mb-16 border-secondary text-secondary">
                                <div className="flex flex-wrap items-center h-full p-10">
                                  <div className="w-full">
                                    <span className="block mb-6 text-xl">Our services help businesses generate leads.</span>
                                  </div>
                                  <div className="w-full">
                                    { title === "Creative" ? (
                                      <Link to="/work/" className="pb-0 mb-0 text-xl text-secondary font-display">See our work <span className="inline-block ml-2 text-primary"><Triangle size={`small`} /></span></Link>
                                    ) : (
                                      <Link to="/services/lead-generation/" className="pb-0 mb-0 text-xl text-secondary font-display">Lead Generation <span className="inline-block ml-2 text-primary"><Triangle size={`small`} /></span></Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </ul>

                      )}
                    </li>
              ))}


                  <li className="hidden mx-3 ml-2 mr-0 xl:ml-5 xl:mx-5 2xl:mx-8 xl:mr-0 2xl:mr-0 md:inline-block">
                    <a href="tel:01159598900" className="align-middle btn btn--small btn--secondary-light lg:text-lg contactlink">
                      <FontAwesomeIcon className="inline-block w-4 h-4 mr-1" icon="phone-alt" size="sm" /> 0115 959 8900
                    </a>
                  </li>

                  <li className="inline-block mx-3 ml-5 mr-0 xl:mx-5 2xl:mx-8 lg:hidden">
                    <button className="align-middle btn btn--small btn--secondary-dark" onClick={this.toggleOverlay}>
                      <FontAwesomeIcon className="inline-block w-3 h-4 mr-2 text-xs" icon="bars" size="sm" />
                      {this.state.overlay ? (<span>Close</span>) : (<span>Menu</span>)}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 z-50 block w-full md:hidden">
          <nav aria-labelledby="mobileMenuTrayToggle">
            <span id="mobileMenuTrayToggle" className="sr-only">Mobile Menu Tray</span>
            <ul className="flex flex-wrap items-center">
              <li className="flex-1">
                <a href="tel:01159598900" className="block w-full text-center btn btn--small btn--secondary-light contactlink">
                  <FontAwesomeIcon className="inline-block w-4 h-4 mr-1" icon="phone-alt" size="sm" /> 0115 959 8900
                </a>
              </li>

              <li className="flex-1">
                <button className="block w-full text-center btn btn--small btn--secondary-dark" onClick={this.toggleOverlay}>
                  <FontAwesomeIcon className="inline-block w-3 h-4 mr-2 text-xs" icon="bars" size="sm" />
                  {this.state.overlay ? (<span>Close</span>) : (<span>Menu</span>)}
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <PoseGroup>
          {this.state.overlay && (
            <Overlay key="overlay" className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full p-8 text-white bg-secondary-dark">
              
              <div className="flex flex-wrap items-center mb-8">
                {/* <Logo color={`white`} modifier={`small`} /> */}
                
                <button className="p-1 ml-auto btn--reset" onClick={this.toggleOverlay}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 18 18"><path fill="#FFF" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
                </button>
              </div>

              <nav aria-labelledby="mobileMenuOverlayLabel" className="mb-16">
                <span id="mobileMenuOverlayLabel" className="sr-only">Mobile Menu</span>
                <ul>
                  <li className="mb-2 text-white lg:block">
                    <Link onClick={this.toggleOverlay} to="/" activeClassName="is--active" className="text-2xl text-white link hover:opacity-75 focus:opacity-75 hover:text-white focus:text-white">Home</Link>
                  </li>
                  {this.state.navItems.map(({ title, url }, index) => (
                    <li key={index} className="mb-2 text-white lg:block">
                      <Link onClick={this.toggleOverlay} to={url} activeClassName="is--active" partiallyActive={true} className="text-2xl text-white link hover:opacity-75 focus:opacity-75 hover:text-white focus:text-white">{title}</Link>
                    </li>
                  ))}
                </ul>
              </nav>

              <div className="flex flex-wrap -mx-3">
                {this.state.socialItems.map(({ title, url, icon }, index) =>
                  <a href={url} target="_blank" rel="noopener noreferrer" className="px-3 text-xl transition text-primary hover:text-primary-dark focus:text-primary-dark" aria-label={`Navigate to ${title}`} key={index}>
                    <FontAwesomeIcon icon={['fab', icon]} />
                  </a>
                )}
              </div>
            </Overlay>
          )}
        </PoseGroup>
      </header>
    )
  }
}

Header.propTypes = {
  navItems: PropTypes.array,
  socialItems: PropTypes.array
}

Header.defaultProps = {
  navItems: [],
  socialItems: []
}

export default Header