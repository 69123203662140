import React from "react"
import PropTypes from "prop-types"

const Logo = ({ color, modifier }) => (
  <svg
    viewBox="0 0 160 35"
    className={modifier ? `logo text-${color} logo--${modifier}` : `logo text-${color}` }
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.639 9.057v25.3h-4.131l-.499-3.075c-2.256 2.318-5.404 3.707-9.035 3.707C5.55 34.987 0 29.29 0 21.71 0 14.104 5.55 8.453 12.974 8.453c3.673 0 6.852 1.408 9.114 3.767l.599-3.164h3.952zm-5.133 12.651c0-4.767-3.433-8.301-8.129-8.301-4.695 0-8.162 3.563-8.162 8.301 0 4.736 3.464 8.302 8.162 8.302 4.699 0 8.129-3.534 8.129-8.302zM56.806 0v34.37h-3.873l-.66-2.869C50.033 33.694 46.971 35 43.475 35c-7.535 0-12.953-5.697-12.953-13.28 0-7.617 5.418-13.268 12.953-13.268 3.198 0 6.033 1.084 8.208 2.944V0h5.123zM43.879 30.02c4.695 0 8.162-3.597 8.162-8.3 0-4.737-3.464-8.302-8.162-8.302s-8.128 3.533-8.128 8.301c0 4.768 3.432 8.302 8.128 8.302zM76.895 29.646v4.725h-3.807c-5.457 0-8.827-3.421-8.827-9.008V13.525h-4.455v-1.007l8.666-9.356h.872v5.905h7.414v4.455h-7.374V24.95c0 3.017 1.653 4.696 4.67 4.696h2.841zM95.33 8.928v4.912h-2.705c-4.237 0-6.351 2.453-6.351 7.036v13.492H81.15V9.07h3.464l.868 3.453c1.782-2.398 4.226-3.595 7.63-3.595h2.217zM122.736 9.057v25.3h-4.131l-.499-3.075c-2.256 2.318-5.404 3.707-9.035 3.707-7.421 0-12.974-5.696-12.974-13.28 0-7.604 5.55-13.255 12.974-13.255 3.673 0 6.852 1.408 9.115 3.767l.598-3.164h3.952zM117.6 21.708c0-4.767-3.432-8.301-8.128-8.301s-8.163 3.566-8.163 8.301c0 4.736 3.465 8.302 8.163 8.302 4.696-.003 8.128-3.534 8.128-8.302zM135.434 23.577h-3.106V34.37h-5.123V0h5.123v18.852h3.142l6.726-9.785h6.072l-8.437 12.143 9.333 13.158h-6.261l-7.469-10.791z"
      fill="currentColor"
    />
    <path
      d="M155.887 35c2.272 0 4.113-1.87 4.113-4.176 0-2.307-1.841-4.177-4.113-4.177-2.271 0-4.113 1.87-4.113 4.177 0 2.306 1.842 4.176 4.113 4.176z"
      fill="#FF6B4A"
    />
  </svg>
)

Logo.propTypes = {
  color: PropTypes.string,
  modifier: PropTypes.string,
}

Logo.defaultProps = {
  color: `secondary-dark`,
  modifier: ``,
}

export default Logo
