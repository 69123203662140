import React from "react"
import Layout from "./src/components/layout"

const transitionDelay = 350

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

// THIS UPDATES SCROLL POSITION BACK TO TOP WHEN ANIMATING PAGE TRANSITIONS
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

// ADDING IN CTM SCRIPT
const insertJS = () => {
  const s = document.createElement(`script`)
  s.type = `text/javascript`
  s.src = "//217641.tctm.co/t.js"
  document.getElementsByTagName(`head`)[0].appendChild(s)
  
  const cavy = document.createElement(`script`)
  cavy.type = `text/javascript`
  cavy.src = "https://secure.cavy9soho.com/js/188134.js"
  document.getElementsByTagName(`head`)[0].appendChild(cavy)
}

export const onInitialClientRender = () => {
  insertJS()
}

// export const onRouteUpdate = function({ location }) {
  // Don't track while developing.
  // let fbq = null;
  
  // if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
  //   fbq('track', 'ViewContent');
  // }
// };
