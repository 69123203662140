import React from "react"
import PropTypes from "prop-types"

const Logo = ({ color, modifier }) => (
  

<svg 
    className={modifier ? `logo text-${color} logo--${modifier}` : `logo text-${color}` }
    viewBox="0 0 1562 295"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
            
    <g clip-path="url(#clip0_1_53)">
    <path d="M1215.61 147.59C1215.61 140.64 1222.49 133.27 1221.54 126.58C1220.56 119.72 1211.88 114.59 1209.97 108.08C1208.03 101.46 1212.55 92.45 1209.72 86.26C1206.86 80 1197.07 77.53 1193.38 71.79C1189.66 66.02 1191.46 56.09 1186.98 50.93C1182.49 45.75 1172.41 46.13 1167.23 41.64C1162.06 37.16 1161.01 27.12 1155.23 23.41C1149.49 19.72 1139.93 22.9 1133.67 20.04C1127.48 17.21 1123.64 7.88 1117.02 5.94C1110.51 4.03 1102.24 9.80001 1095.39 8.82001C1088.7 7.87001 1082.38 0 1075.43 0C1068.48 0 1062.16 7.87001 1055.47 8.82001C1048.61 9.80001 1040.35 4.03 1033.84 5.94C1027.22 7.88 1023.38 17.21 1017.19 20.04C1010.93 22.9 1001.37 19.72 995.63 23.41C989.86 27.13 988.8 37.16 983.63 41.64C978.45 46.13 968.37 45.76 963.88 50.93C959.4 56.1 961.2 66.02 957.48 71.79C953.78 77.53 944 80 941.14 86.26C938.31 92.45 942.83 101.46 940.89 108.08C938.98 114.59 930.3 119.73 929.32 126.58C928.37 133.27 935.25 140.63 935.25 147.59C935.25 154.54 928.37 161.91 929.32 168.6C930.3 175.46 938.98 180.59 940.89 187.1C942.83 193.72 938.31 202.73 941.14 208.92C944 215.18 953.79 217.65 957.48 223.39C961.2 229.16 959.4 239.09 963.88 244.25C968.37 249.43 978.45 249.05 983.63 253.54C988.8 258.02 989.85 268.06 995.63 271.77C1001.37 275.46 1010.93 272.28 1017.19 275.14C1023.38 277.97 1027.22 287.3 1033.84 289.24C1040.35 291.15 1048.62 285.38 1055.47 286.36C1062.16 287.31 1068.48 295.18 1075.43 295.18C1082.38 295.18 1088.7 287.31 1095.39 286.36C1102.25 285.38 1110.51 291.15 1117.03 289.24C1123.65 287.3 1127.49 277.97 1133.68 275.14C1139.94 272.28 1149.5 275.46 1155.24 271.77C1161.01 268.05 1162.07 258.02 1167.24 253.54C1172.42 249.05 1182.5 249.42 1186.99 244.25C1191.47 239.08 1189.67 229.16 1193.39 223.39C1197.08 217.65 1206.87 215.18 1209.73 208.92C1212.56 202.73 1208.04 193.72 1209.98 187.1C1211.89 180.59 1220.57 175.45 1221.55 168.6C1222.49 161.91 1215.61 154.55 1215.61 147.59Z" fill="#12264A"/>
    <path d="M1148.41 84.0601H1562.03L1498.5 147.59L1562.03 211.12H1148.41V84.0601Z" fill="#12264A"/>
    <path d="M1075.41 259.67C1137.31 259.67 1187.49 209.49 1187.49 147.59C1187.49 85.69 1137.31 35.51 1075.41 35.51C1013.51 35.51 963.33 85.69 963.33 147.59C963.33 209.49 1013.51 259.67 1075.41 259.67Z" fill="#FF6B4A"/>
    <path d="M1075.41 38.26C1135.79 38.26 1184.74 87.21 1184.74 147.59C1184.74 207.97 1135.79 256.92 1075.41 256.92C1015.03 256.92 966.08 207.97 966.08 147.59C966.08 87.21 1015.03 38.26 1075.41 38.26ZM1075.41 32.76C1044.74 32.76 1015.9 44.7 994.21 66.39C972.52 88.08 960.58 116.91 960.58 147.59C960.58 178.27 972.52 207.1 994.21 228.79C1015.9 250.48 1044.73 262.42 1075.41 262.42C1106.08 262.42 1134.92 250.48 1156.61 228.79C1178.3 207.1 1190.24 178.27 1190.24 147.59C1190.24 116.91 1178.3 88.08 1156.61 66.39C1134.92 44.71 1106.08 32.76 1075.41 32.76Z" fill="white"/>
    <path d="M1069.39 175.87V194.8H1003.57V190.39C1042.23 145.72 1047.29 138.4 1047.29 128.69C1047.29 122.17 1042.9 117.81 1036.53 117.81C1029.86 117.81 1025.53 122.32 1025.53 128.95V130.75H1005.34V128.72C1005.34 111.48 1017.43 99 1036.18 99C1054.62 99 1067.61 111.22 1067.61 128.47C1067.61 140 1062.66 150.5 1040.72 175.88H1069.39V175.87Z" fill="white"/>
    <path d="M1113.74 133.42C1133.35 133.42 1147.25 146.46 1147.25 164.26C1147.25 182.51 1133.09 196.19 1114.38 196.19C1095.44 196.19 1081.52 182.96 1081.42 164.97H1101.81C1101.91 172.16 1107.14 177.37 1114.37 177.37C1121.52 177.37 1126.91 171.77 1126.91 164.25C1126.91 156.24 1121.19 150.59 1112.96 150.59H1086.62L1084.69 148.04L1089.95 100.6H1140.74V118.65H1105.89L1104.23 133.41H1113.74V133.42Z" fill="white"/>
    <path d="M1261.15 151.44V167.64H1252.25V151.39L1238.24 127.52H1247.48L1256.75 143.58L1266.03 127.52H1275.28L1261.15 151.44Z" fill="white"/>
    <path d="M1316.57 159.56V167.64H1291.85V127.51H1315.94V135.53H1300.53V143.46H1314.45V151.32H1300.53V159.56H1316.57Z" fill="white"/>
    <path d="M1358.79 160.03H1342.82L1340.07 167.64H1330.86L1346.59 127.51H1354.79L1371.02 167.64H1361.6L1358.79 160.03ZM1345.53 152.46H1356.06L1350.77 138.11L1345.53 152.46Z" fill="white"/>
    <path d="M1401.43 153.94H1395.86V167.64H1387.18V127.51H1403.38C1411.42 127.51 1417.41 133.04 1417.41 140.78C1417.41 146.03 1414.53 150.32 1410.1 152.46L1418.98 167.63H1409.28L1401.43 153.94ZM1395.86 145.92H1402.81C1406.44 145.92 1408.61 143.74 1408.61 140.72C1408.61 137.72 1406.44 135.52 1402.81 135.52H1395.86V145.92Z" fill="white"/>
    <path d="M1433.89 154.63H1442.64C1442.64 158.51 1445.85 160.38 1449.39 160.38C1452.66 160.38 1455.85 158.66 1455.85 155.66C1455.85 152.55 1452.19 151.69 1447.84 150.68C1441.79 149.19 1434.44 147.43 1434.44 138.79C1434.44 131.09 1440.11 126.76 1449 126.76C1458.22 126.76 1463.47 131.68 1463.47 139.68H1454.89C1454.89 136.22 1452.04 134.6 1448.79 134.6C1445.98 134.6 1443.13 135.79 1443.13 138.42C1443.13 141.24 1446.62 142.1 1450.86 143.12C1456.98 144.67 1464.66 146.58 1464.66 155.57C1464.66 164.24 1457.75 168.44 1449.45 168.44C1440.24 168.43 1433.89 163.26 1433.89 154.63Z" fill="white"/>
    <path d="M157.9 98.2999V245.99H133.41L130.45 228.03C117.07 241.56 98.42 249.67 76.89 249.67C32.9 249.67 0 216.42 0 172.15C0 127.76 32.9 94.7899 76.89 94.7899C98.66 94.7899 117.5 103.01 130.91 116.78L134.46 98.3099L157.9 98.2999ZM127.47 172.15C127.47 144.31 107.13 123.69 79.29 123.69C51.45 123.69 30.91 144.5 30.91 172.15C30.91 199.8 51.45 220.61 79.29 220.61C107.13 220.61 127.47 199.99 127.47 172.15Z" fill="#12264A"/>
    <path d="M336.71 45.4399V246.07H313.75L309.84 229.32C296.55 242.13 278.41 249.75 257.7 249.75C213.04 249.75 180.92 216.5 180.92 172.23C180.92 127.76 213.04 94.7899 257.7 94.7899C276.65 94.7899 293.45 101.12 306.35 111.97V45.4399H336.71ZM260.09 220.68C287.93 220.68 308.47 199.67 308.47 172.23C308.47 144.58 287.93 123.77 260.09 123.77C232.25 123.77 211.91 144.39 211.91 172.23C211.91 200.07 232.25 220.68 260.09 220.68Z" fill="#12264A"/>
    <path d="M455.78 218.49V246.07H433.21C400.86 246.07 380.89 226.1 380.89 193.48V124.39H354.48V118.52L405.85 63.9099H411.02V98.3899H454.96V124.4H411.25V191.1C411.25 208.71 421.05 218.51 438.93 218.51H455.78V218.49Z" fill="#12264A"/>
    <path d="M565.04 97.5599V126.24H549.01C523.9 126.24 511.37 140.56 511.37 167.31V246.07H481.01V98.3799H501.54L506.68 118.54C517.25 104.53 531.74 97.5599 551.91 97.5599H565.04Z" fill="#12264A"/>
    <path d="M727.48 98.2999V245.99H702.99L700.03 228.03C686.65 241.56 667.99 249.67 646.47 249.67C602.48 249.67 569.58 216.42 569.58 172.15C569.58 127.76 602.48 94.7899 646.47 94.7899C668.24 94.7899 687.08 103.01 700.49 116.78L704.04 98.3099L727.48 98.2999ZM697.05 172.15C697.05 144.31 676.71 123.69 648.87 123.69C621.03 123.69 600.49 144.5 600.49 172.15C600.49 199.8 621.03 220.61 648.87 220.61C676.71 220.6 697.05 199.99 697.05 172.15Z" fill="#12264A"/>
    <path d="M802.76 183.07H784.36V246.07H754V45.4399H784.36V155.49H802.98L842.84 98.3799H878.82L828.81 169.27L884.13 246.07H847.01L802.76 183.07Z" fill="#12264A"/>
    </g>
    <defs>
    <clipPath id="clip0_1_53">
    <rect width="1562" height="295" fill="white"/>
    </clipPath>
    </defs>

</svg>

)

Logo.propTypes = {
  color: PropTypes.string,
  modifier: PropTypes.string,
}

Logo.defaultProps = {
  color: `secondary-dark`,
  modifier: ``,
}

export default Logo
