import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import CookieConsent from "react-cookie-consent"

import Process from "../components/process/process"
import Reviews from "../components/reviews/reviews"
import ImageRandomiser from "../components/imageRandomiser/imageRandomiser"
import Header from "./header/header"
import Footer from "./footer/footer"
import Img from "gatsby-image"
import googlePartner from '../images/google-partner.png'

import "../styles/main.css"

import { library } from "@fortawesome/fontawesome-svg-core"
import { faPhoneAlt, faStar, faBars, faTimes, faEnvelope, faExclamationCircle, faPaintBrush, faWrench, faCommentDots, faMapMarker, faUserFriends, faSearch, faLaptopCode, faMapPin } from "@fortawesome/free-solid-svg-icons"
import { faLinkedinIn, faFacebookF, faTwitter, faXTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

library.add(faPhoneAlt, faStar, faLinkedinIn, faXTwitter, faFacebookF, faTwitter, faInstagram, faBars, faTimes, faEnvelope, faExclamationCircle, faPaintBrush, faWrench, faCommentDots, faMapMarker, faUserFriends, faSearch, faLaptopCode, faMapPin)


const navItems = [
  {
    title: "Who We Are",
    url: "/who-we-are/",
    children: [
      {
        title: "Careers",
        url: "/careers/"
      },
      {
        title: "Meet The Team",
        url: "/meet-the-team"
      }
    ],
  },{
    title: "Digital",
    url: "/services/",
    children: [
     
      {
        title: "Web Design",
        url: "/services/web-design",
        icon: "/web.svg",
        children: [
          {
            title: "Web Development",
            url: "/services/web-design/web-development",
          },
          {
            title: "WordPress Web Design",
            url: "/services/web-design/wordpress-web-design",
          },
          {
            title: "Bespoke Web Design",
            url: "/services/web-design/bespoke-web-design",
          },
          {
            title: "Lead Generation Websites",
            url: "/services/web-design/lead-generation-websites",
          },
          {
            title: "Ecommerce Agency",
            url: "/services/web-design/ecommerce-agency",
            children: [
              {
                title: "WooCommerce Agency",
                url: "/services/web-design/ecommerce-agency/woocommerce-agency",
              },
              {
                title: "Shopify Agency",
                url: "/services/web-design/ecommerce-agency/shopify-agency",
              }
            ]
          }
        ]
      },
      {
        title: "PPC",
        url: "/services/ppc",
        icon: "/ppc.svg",
        children: [
          {
            title: "Ecommerce PPC",
            url: "/services/ppc/ecommerce-ppc",
          },
          {
            title: "Google Shopping",
            url: "/services/ppc/google-shopping",
          },
          {
            title: "Google Ads Management",
            url: "/services/ppc/google-ads-management",
          },
          {
            title: "PPC Audit",
            url: "/services/ppc/ppc-audit",
          },
          {
            title: "ALD Advanced Location Dynamics",
            url: "/services/telecoms/ald-advanced-location-dynamics"
          }
        ]
      },
      {
        title: "SEO",
        url: "/services/seo",
        icon: "/seo.svg",
        children: [
          {
            title: "Ecommerce SEO",
            url: "/services/seo/ecommerce-seo",
          },
          {
            title: "Local SEO",
            url: "/services/seo/local-seo-services",
          },
          {
            title: "SEO Audits",
            url: "/services/seo/seo-audits",
          },
          {
            title: "Free Ranking Report",
            url: "/services/seo/free-ranking-report",
          }
        ]
      },
      {
        title: "Content Marketing",
        url: "/services/content-marketing",
        icon: "/copy.svg",
        children: [
          {
            title: "Digital PR",
            url: "/services/content-marketing/digital-pr",
          }
        ]
      },
      {
        title: "Social Marketing",
        url: "/services/social-marketing",
        icon: "/social.svg",
        children: [
          {
            title: "Social Media Advertising",
            url: "/services/social-marketing/social-media-advertising",
          },
          {
            title: "Facebook Advertising",
            url: "/services/social-marketing/facebook-advertising",
          }
        ]
      },
      {
        title: "CRO",
        url: "/services/cro",
        icon: "/cro.svg"
      },
      {
        title: "Telecoms",
        url: "/services/telecoms",
        icon: "/telecoms.svg",
        children: [
          {
            title: "ALD Advanced Location Dynamics",
            url: "/services/telecoms/ald-advanced-location-dynamics"
          }
        ]
      }
    ]
  },{
    title: "Creative",
    url: "/creative-services/",
    children: [
      {
        title: "Brand Strategy",
        url: "/creative-services/brand-strategy",
        icon: "/brand-strategy.svg"
      },
      {
        title: "Branding",
        url: "/creative-services/branding",
        icon: "/brand-guidelines.svg",
        children: [
          {
            title: "Rebranding",
            url: "/creative-services/branding/rebranding",
          },
          {
            title: "Logo Design",
            url: "/creative-services/branding/logo-design",
          }
        ]
      },
      {
        title: "Graphic Design",
        url: "/creative-services/design",
        icon: "/graphics.svg"
      },
      {
        title: "Brand Guidelines",
        url: "/creative-services/brand-guidelines",
        icon: "/branding.svg"
      },
      {
        title: "Copywriting",
        url: "/creative-services/copywriting",
        icon: "/copy.svg"
      },
      {
        title: "Videography",
        url: "/creative-services/videography",
        icon: "/videography.svg",
        children: [
          {
            title: "Animation Video Production",
            url: "/creative-services/videography/animation-video-production",
          },
          {
            title: "3D Showcase",
            url: "/creative-services/videography/showcase",
          }
        ]
      },
      {
        title: "Photography",
        url: "/creative-services/photography",
        icon: "/photography.svg"
      }
    ]
  },
  {
    title: "Strategy",
    url: "/services/digital-strategy/"
  },{
    title: "Our Work",
    url: "/work/"
  },
  {
    title: "Careers",
    url: "/careers/",
    mobnavonly: true,
  },
  {
    title: "Meet The Team",
    url: "/meet-the-team",
    mobnavonly: true,
  },{
    title: "Blog",
    url: "/blog/"
  },{
    title: "Contact",
    url: "/contact/"
}]

const socialItems = [
  {
    title: "Facebook",
    url: "https://en-gb.facebook.com/adtrak",
    icon: "facebook-f"
  },{
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/adtrak",
    icon: "linkedin-in"
  },{
    title: "Twitter",
    url: "https://twitter.com/adtrak",
    icon: "x-twitter"
  },{
    title: "Instagram",
    url: "https://www.instagram.com/adtrak",
    icon: "instagram"
}]

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      google: file(relativePath: { eq: "google-premier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      socialappshq: file(relativePath: { eq: "socialappshq.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bing: file(relativePath: { eq: "bing-accredited.png" }) { 
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dan: file(relativePath: { eq: "dan-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landscaper: file(relativePath: { eq: "landscaper-award.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image: file(relativePath: { eq: "adtrakchairs.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "adtrakchairs2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "adtrakchairs3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imagesForRandomiser = [
    data.image.childImageSharp,
    data.image2.childImageSharp,
    data.image3.childImageSharp,
  ]

  return (
    <div className="pb-8 md:pb-0">

      
        <Header
          siteTitle={"Adtrak"}
          navItems={navItems}
          socialItems={socialItems}
          greyHeader={(location.pathname.includes('/digital-marketing-agency/') ? (true) : (false) )}
        />
      
      
      
          {children}


        <AnimatePresence>
          
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >

          { (location.pathname === '/contact' || location.pathname === '/contact/' || location.pathname === '/who-we-are' || location.pathname === '/who-are-we/' || location.pathname === '/direct-debit' || location.pathname === '/direct-debit/') ? '' : <Process /> }
          
          { (location.pathname === '/contact' || location.pathname === '/contact/') ? '' : <ImageRandomiser images={imagesForRandomiser} /> }
          

          { (location.pathname === '/direct-debit' || location.pathname === '/direct-debit/') ? '' : 

          <section className="py-12 border-b-2 md:py-20 lg:py-32 border-grey">
            <div className="container">
              <div className="flex flex-wrap items-center">
                <div className="order-2 w-full lg:w-4/12 lg:order-1">
                  <span className="block mb-8 h2">Looking for more leads for your business? Let's work together.</span>
                  
                  { (location.pathname === '/contact' || location.pathname === '/contact/') ? (
                    <a href="#form" className="capitalize btn">Start your journey</a>
                  ) : (
                    <Link to="/contact/" className="capitalize btn">Start your journey</Link>
                  )}
                </div>

                <div className="flex flex-wrap items-center justify-center order-1 w-full mb-8 lg:w-8/12 md:mx-auto lg:order-2 md:mb-0">

                  <div className="block px-8 mx-auto mb-8 sm:w-1/2 xl:w-1/5 2xl:mx-0 xl:mb-0 md:px-2 md:py-4">
                  <a href="https://www.google.com/partners/agency?id=5691807449" target="_blank" rel="noreferrer" className="flex justify-center">
                      <img src={googlePartner} alt="Google Partner" loading="lazy" width="140px" height="140px" />
                  </a>
                  </div>
                  <div className="block px-8 mx-auto mb-8 sm:w-1/2 xl:w-1/5 2xl:mx-0 xl:mb-0 md:px-2 md:py-4">
                  <a href="https://www.socialappshq.com/seo/best-agencies/uk/nottingham/" target="_blank" rel="noreferrer" className="flex justify-center">
                    <Img className="block w-40 mx-auto sm:w-38 xl:w-32 2xl:w-38" fluid={data.socialappshq.childImageSharp.fluid} />
                  </a>
                  </div>
                  <div className="block px-8 mx-auto mb-8 sm:w-1/2 xl:w-1/5 2xl:mx-0 xl:mb-0 md:px-2 md:py-4">
                    <Img className="block w-40 mx-auto sm:w-38 xl:w-32 2xl:w-38" fluid={data.bing.childImageSharp.fluid} />
                  </div>
                  <div className="block px-8 mx-auto mb-8 sm:w-1/2 xl:w-1/5 2xl:mx-0 xl:mb-0 md:px-2 md:py-4">
                    <Img className="block w-40 mx-auto sm:w-38 xl:w-32 2xl:w-38" fluid={data.dan.childImageSharp.fluid} />
                  </div>
                  <div className="block px-8 mx-auto mb-8 sm:w-1/2 xl:w-1/5 2xl:mx-0 xl:mb-0 md:px-2 md:py-4">
                    <Reviews />
                  </div>
                </div>
              </div>
            </div>
          </section>

          }

          <Footer
            navItems={navItems}
            socialItems={socialItems}
          />

          <CookieConsent
              location="bottom"
              style={{ display: "none", background: "#F5F5F5", justifyContent: "center", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px", paddingRight: "10px", opacity: "90%" }}
              buttonClasses="btn btn--smaller btn--outline w-full block md:w-auto md:inline-block pl"
              contentStyle={{ margin: "0px", padding: "0px" }}
              buttonStyle={{ marginTop: "0px", marginBottom: "0px", background: "#12284C", color: "#FFFFFF", marginLeft: "0px", fontSize: "14px", border: "1px solid #12284C", display: "block" }}
              expires={150}
          >
            <div className="flex flex-wrap items-start mb-8 md:mb-0">
              <div className="flex-1 pl-0 md:w-7/12">
                <span className="text-center text-secondary-dark">Our website uses cookies to enhance your browsing experience...</span>
              </div>
              <div className="ml-auto">
                <Link to="/privacy-policy" className="block ml-6 mr-2 -mt-1 text-sm btn btn--smaller btn--outline md:mr-4">More Info<span className="hidden md:inline-block">rmation</span></Link>
              </div>
            </div>
          </CookieConsent>

        </motion.main>
      </AnimatePresence>
      
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
